<aca-page-layout [hasError]="!isValidPath">
  <div class="aca-page-layout-header">
    <aca-search-ai-input-container
      *ngIf="searchAiInputState.active; else header"
      [agentId]="searchAiInputState.selectedAgentId" />
    <ng-template #header>
      <div class="aca-header-container">
        <adf-breadcrumb
          [root]="title"
          [folderNode]="node"
          [selectedRowItemsCount]="selectedRowItemsCount" [maxItems]="isSmallScreen ? 1 : 0"
          (navigate)="onBreadcrumbNavigate($event)" />
        <aca-toolbar [items]="actions" />
      </div>
    </ng-template>
  </div>

  <div class="aca-page-layout-error">
    <aca-generic-error [text]="errorTranslationKey" />
  </div>

  <div class="aca-page-layout-content">
    <div class="aca-main-content" *ngIf="!(showLoader$ | async)">
      <adf-upload-drag-area [rootFolderId]="node?.id" [disabled]="!canUpload" (updateFileVersion)="onUploadNewVersion($event)">
        <adf-document-list
          #documentList
          acaDocumentList
          acaContextActions
          [selectionMode]="'multiple'"
          [multiselect]="true"
          [currentFolderId]="node?.id"
          [loading]="true"
          [showHeader]="showHeader"
          [node]="nodeResult"
          [allowDropFiles]="true"
          [displayCheckboxesOnHover]="true"
          [preselectNodes]="selectedNodesState?.nodes"
          [navigate]="false"
          [sorting]="['name', 'asc']"
          [imageResolver]="imageResolver"
          [headerFilters]="true"
          [filterValue]="queryParams"
          [isResizingEnabled]="true"
          [blurOnResize]="false"
          [displayDragAndDropHint]="canUpload"
          (node-dblclick)="handleNodeClick($event)"
          (name-click)="handleNodeClick($event)"
          (selectedItemsCountChanged)="onSelectedItemsCountChanged($event)"
          (filterSelection)="onFilterSelected($event)"
          (error)="onError($event)"
        >
          <data-columns>
            <ng-container *ngFor="let column of columns; trackBy: trackByColumnId">
              <ng-container *ngIf="column.template && !(column.desktopOnly && isSmallScreen)">
                <data-column
                  [id]="column.id"
                  [key]="column.key"
                  [title]="column.title"
                  [type]="column.type"
                  [format]="column.format"
                  [class]="column.class"
                  [sortable]="column.sortable"
                  [sortingKey]="column.sortingKey || column.key"
                  [isHidden]="column.isHidden"
                  [draggable]="column.draggable"
                  [resizable]="column.resizable"
                >
                  <ng-template let-context>
                    <adf-dynamic-column [id]="column.template" [context]="context" />
                  </ng-template>
                </data-column>
              </ng-container>

              <ng-container *ngIf="!column.template && !(column.desktopOnly && isSmallScreen)">
                <data-column
                  [id]="column.id"
                  [key]="column.key"
                  [title]="column.title"
                  [type]="column.type"
                  [format]="column.format"
                  [class]="column.class"
                  [sortable]="column.sortable"
                  [sortingKey]="column.sortingKey || column.key"
                  [isHidden]="column.isHidden"
                  [draggable]="column.draggable"
                  [resizable]="column.resizable"
                />
              </ng-container>
            </ng-container>
          </data-columns>

          <adf-custom-empty-content-template *ngIf="isFilterHeaderActive">
            <ng-container>
              <div class="empty-search__block" aria-live="polite">
                <p class="empty-search__text">
                  {{ 'APP.BROWSE.SEARCH.NO_FILTER_RESULTS' | translate }}
                </p>
              </div>
            </ng-container>
          </adf-custom-empty-content-template>
        </adf-document-list>

        <adf-pagination acaPagination [target]="documentList" />
      </adf-upload-drag-area>
    </div>

    <mat-progress-spinner *ngIf="showLoader$ | async"
      id="adf-document-list-loading"
      class="adf-document-list-loading-margin"
      [color]="'primary'"
      [mode]="'indeterminate'" />

    <div class="aca-sidebar" *ngIf="infoDrawerOpened$ | async">
      <aca-info-drawer [node]="selection.last" />
    </div>
  </div>
</aca-page-layout>
