<div class="aca-custom-name-column">
  <div class="aca-name-column-container">
    <span
      role="link"
      tabindex="0"
      [attr.aria-label]="
        (isFile ? 'CUSTOM_NAME_COLUMN.ACCESSIBILITY.FILE_LINK_ARIA_LABEL' : 'CUSTOM_NAME_COLUMN.ACCESSIBILITY.FOLDER_LINK_ARIA_LABEL')
          | translate : { name: displayText$ | async }
      "
      class="adf-datatable-cell-value"
      title="{{ node | adfNodeNameTooltip }}"
      (click)="onLinkClick($event)"
      (keyup.enter)="onLinkClick($event)"
    >
      {{ displayText$ | async }}
    </span>

    <ng-container *ngIf="isFile && isFileWriteLocked">
      <aca-locked-by [node]="context.row.node" />
    </ng-container>
  </div>
  <aca-datatable-cell-badges [node]="node" />
</div>
