<div class="aca-rule-list-item__header">

  <span class="aca-rule-list-item__header__name">{{ rule.name }}</span>

  <mat-slide-toggle
    *ngIf="showEnabledToggle"
    [checked]="rule.isEnabled"
    [aria-label]="'ACA_FOLDER_RULES.RULE_LIST.TOGGLE_RULE_STATE' | translate"
    (click)="onToggleClick(!rule.isEnabled, $event)" />

</div>
<div class="aca-rule-list-item__description">{{ rule.description }}</div>
