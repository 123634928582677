<div class="aca-sidenav">
    <app-sidenav-header (toggleNavBar)="toggleClick()" />

    <div class="aca-sidenav__section">
      <div *ngFor="let group of groups; trackBy: trackByGroupId" class="aca-sidenav__section__actions">
          <mat-list-item class="aca-sidenav__section__actions__item" *ngFor="let item of group.items; trackBy: trackByLinkId">
            <ng-container *ngIf="!item.component">
              <app-expand-menu [item]="item" />
            </ng-container>
            <ng-container *ngIf="item.component">
              <adf-dynamic-component [data]="{ item: item, state: 'expanded' }" [id]="item.component" />
            </ng-container>
          </mat-list-item>
      </div>
    </div>
</div>
