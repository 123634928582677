<aca-page-layout [class.aca-search-results-active-search-ai-input]="searchAiInputState.active">
  <div class="aca-page-layout-header">
    <aca-search-ai-input-container
      *ngIf="searchAiInputState.active"
      [agentId]="searchAiInputState.selectedAgentId" />
    <div class="aca-header-container">
      <aca-search-input />
      <aca-bulk-actions-dropdown *ngIf="bulkActions" [items]="bulkActions" />
      <div class="aca-search-toolbar-spacer"></div>
      <aca-toolbar [items]="actions" />
    </div>
  </div>

  <div class="aca-page-layout-content">
    <div class="aca-main-content">
      <div class="adf-search-results">
        <div class="adf-search-results__content">
          <mat-progress-bar *ngIf="isLoading" color="primary" mode="indeterminate" aria-live="polite" />
          <div class="adf-search-results__content-header aca-content">
            <div class="aca-content__filter-set">
              <p>{{ 'APP.BROWSE.SEARCH.FILTER_SET' | translate }}</p>
              <adf-search-form />
            </div>
            <mat-divider [vertical]="true" class="aca-content__divider" />
            <div class="aca-content__advanced-filters">
              <div class="aca-content__advanced-filters--header">
                <p>{{ 'APP.BROWSE.SEARCH.ADVANCED_FILTERS' | translate }}</p>
                <div class="aca-content__advanced-filters--header--action-buttons">
                  <button
                    *ngIf="initialSavedSearch !== undefined else saveSearchButton"
                    mat-button
                    [disabled]="!encodedQuery"
                    class="aca-content__save-search-action"
                    title="{{ 'APP.BROWSE.SEARCH.SAVE_SEARCH.ACTION_BUTTON' | translate }}"
                    [attr.aria-label]="'APP.BROWSE.SEARCH.SAVE_SEARCH.ACTION_BUTTON' | translate "
                    [matMenuTriggerFor]="saveSearchOptionsMenu">
                    {{ 'APP.BROWSE.SEARCH.SAVE_SEARCH.ACTION_BUTTON' | translate }}
                    <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
                  </button>
                  <mat-menu #saveSearchOptionsMenu="matMenu">
                    <button
                      mat-menu-item
                      (click)="editSavedSearch(initialSavedSearch)"
                      title="{{ 'APP.BROWSE.SEARCH.SAVE_SEARCH.SAVE_CHANGES' | translate }}"
                      [attr.aria-label]="'APP.BROWSE.SEARCH.SAVE_SEARCH.SAVE_CHANGES' | translate ">
                      {{ 'APP.BROWSE.SEARCH.SAVE_SEARCH.SAVE_CHANGES' | translate }}
                    </button>
                    <button
                      mat-menu-item
                      acaSaveSearch
                      [acaSaveSearchQuery]="encodedQuery"
                      title="{{ 'APP.BROWSE.SEARCH.SAVE_SEARCH.SAVE_AS_NEW' | translate }}"
                      [attr.aria-label]="'APP.BROWSE.SEARCH.SAVE_SEARCH.SAVE_AS_NEW' | translate ">
                      {{ 'APP.BROWSE.SEARCH.SAVE_SEARCH.SAVE_AS_NEW' | translate }}
                    </button>
                  </mat-menu>
                  <ng-template #saveSearchButton>
                    <button
                      mat-button
                      acaSaveSearch
                      [acaSaveSearchQuery]="encodedQuery"
                      [disabled]="!encodedQuery"
                      class="aca-content__save-search-action"
                      title="{{ 'APP.BROWSE.SEARCH.SAVE_SEARCH.ACTION_BUTTON' | translate }}"
                      [attr.aria-label]="'APP.BROWSE.SEARCH.SAVE_SEARCH.ACTION_BUTTON' | translate ">
                      {{ 'APP.BROWSE.SEARCH.SAVE_SEARCH.ACTION_BUTTON' | translate }}
                    </button>
                  </ng-template>
                  <button
                    mat-button
                    adf-reset-search
                    class="aca-content__reset-action"
                    title="{{ 'APP.BROWSE.SEARCH.RESET_ACTION' | translate }}"
                    [attr.aria-label]="'APP.BROWSE.SEARCH.RESET_ACTION' | translate ">
                    {{ 'APP.BROWSE.SEARCH.RESET' | translate }}
                  </button>
                </div>
              </div>
              <adf-search-filter-chips />
            </div>
          </div>

          <adf-document-list
            #documentList
            acaDocumentList
            acaContextActions
            [selectionMode]="'multiple'"
            [multiselect]="true"
            [sortingMode]="'server'"
            [sorting]="sorting"
            [preselectNodes]="selectedNodesState?.nodes"
            [displayCheckboxesOnHover]="true"
            [imageResolver]="imageResolver"
            [isResizingEnabled]="true"
            [blurOnResize]="false"
            [node]="$any(data)"
            (node-dblclick)="handleNodeClick($event)"
          >
            <data-columns>
              <data-column id="app.search.thumbnail" key="$thumbnail" type="image" [sr-title]="'ADF-DOCUMENT-LIST.LAYOUT.THUMBNAIL'" [sortable]="false">
                <ng-template let-context>
                  <aca-custom-thumbnail-column [context]="context" />
                </ng-template>

                <adf-data-column-header>
                  <ng-template>
                    <aca-search-action-menu (sortingSelected)="onSearchSortingUpdate($event)" />
                  </ng-template>
                </adf-data-column-header>
              </data-column>

              <ng-container *ngFor="let column of columns; trackBy: trackByColumnId">
                <ng-container *ngIf="column.template && !(column.desktopOnly && isSmallScreen)">
                  <data-column
                    [id]="column.id"
                    [key]="column.key"
                    [title]="column.title"
                    [type]="column.type"
                    [format]="column.format"
                    [class]="column.class"
                    [sortable]="column.sortable"
                    [sortingKey]="column.sortingKey || column.key"
                    [isHidden]="column.isHidden"
                    [draggable]="column.draggable"
                    [resizable]="column.resizable"
                  >
                    <ng-template let-context>
                      <adf-dynamic-column [id]="column.template" [context]="context" />
                    </ng-template>
                  </data-column>
                </ng-container>

                <ng-container *ngIf="!column.template && !(column.desktopOnly && isSmallScreen)">
                  <data-column
                    [id]="column.id"
                    [key]="column.key"
                    [title]="column.title"
                    [type]="column.type"
                    [format]="column.format"
                    [class]="column.class"
                    [sortable]="column.sortable"
                    [sortingKey]="column.sortingKey || column.key"
                    [isHidden]="column.isHidden"
                    [draggable]="column.draggable"
                    [resizable]="column.resizable"
                  />
                </ng-container>
              </ng-container>
            </data-columns>

            <adf-custom-empty-content-template>
              <ng-container *ngIf="data">
                <div class="empty-search__block" aria-live="polite">
                  <p class="empty-search__text">
                    {{ 'APP.BROWSE.SEARCH.NO_RESULTS' | translate }}
                  </p>
                </div>
              </ng-container>
            </adf-custom-empty-content-template>
          </adf-document-list>

          <adf-pagination *ngIf="totalResults > 0" acaPagination [target]="documentList" (change)="onPaginationChanged($event)" />
        </div>
      </div>
    </div>
    <div
      [ngClass]="
        (infoDrawerPreview$ | async) === true ? 'adf-search-results--right_panel_section-extended' : 'adf-search-results--right_panel_section'
      "
      *ngIf="infoDrawerOpened$ | async"
    >
      <adf-alfresco-viewer
        class="adf-search-results--embedded_viewer"
        [nodeId]="selection.last.entry.id"
        *ngIf="infoDrawerPreview$ | async; else infoDrawerPanel"
      >
        <adf-viewer-toolbar>
          <div class="adf-search-results--preview-toolbar">
            <div>
              <button mat-icon-button title="{{ 'ADF_VIEWER.ACTIONS.CLOSE' | translate }}" (click)="onDrawerClosed()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <div>
              <button
                mat-icon-button
                title="{{ 'ADF_VIEWER.ACTIONS.PREVIEW' | translate }}"
                color="accent"
                class="adf-search-results--visibility_button"
              >
                <mat-icon>visibility</mat-icon>
              </button>
              <button mat-icon-button title="{{ 'ADF_VIEWER.ACTIONS.INFO' | translate }}" (click)="onPreviewClosed()">
                <mat-icon>info_outline</mat-icon>
              </button>
            </div>
          </div>
        </adf-viewer-toolbar>
      </adf-alfresco-viewer>
      <ng-template #infoDrawerPanel>
        <div class="aca-sidebar">
          <aca-info-drawer [node]="selection.last" />
        </div>
      </ng-template>
    </div>
  </div>
</aca-page-layout>
