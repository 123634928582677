<mat-form-field
  *ngIf="items?.length"
  [title]="tooltip"
  appearance="outline"
  subscriptSizing="dynamic"
  class="aca-bulk-actions-form-field"
  data-automation-id="aca-bulk-actions-form-field"
>
  <mat-select
    [formControl]="bulkSelectControl"
    [placeholder]="placeholder"
    panelClass="aca-bulk-actions-select"
    disableOptionCentering
    data-automation-id="aca-bulk-actions-dropdown"
    (keydown)="onKeyDown($event)"
  >
    <mat-select-trigger>
      <div class="aca-bulk-actions-option-content">
        <adf-icon
          *ngIf="bulkSelectControl.value?.icon"
          [title]="bulkSelectControl.value?.title | translate"
          [value]="bulkSelectControl.value?.icon"
          class="aca-bulk-actions-icon"
          [attr.data-automation-id]="'aca-bulk-action-icon-' + bulkSelectControl.value?.id"
        />
        {{ bulkSelectControl.value?.title | translate }}
      </div>
    </mat-select-trigger>

    <mat-option
      *ngFor="let option of items"
      [value]="option"
      [title]="option.tooltip | translate"
      [attr.data-automation-id]="option.id"
      (keyup.enter)="runAction(option)"
      (click)="runAction(option)"
    >
      <div class="aca-bulk-actions-option-content">
        <adf-icon
          *ngIf="option.icon"
          [title]="option.title | translate"
          [value]="option.icon"
          class="aca-bulk-actions-icon"
          [attr.data-automation-id]="'aca-bulk-action-icon-' + option.id"
        />
        {{ option.title | translate }}
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
