<ng-container *ngIf="!item.children">
  <div class="aca-expansion-panel-item">
    <button
      acaActiveLink="aca-action-button--active"
      [action]="item"
      [attr.aria-label]="item.title | translate"
      [id]="item.id"
      [attr.data-automation-id]="item.id"
      [attr.title]="item.description | translate"
      mat-button
      class="aca-action-button aca-full-width"
    >
      <adf-icon *ngIf="item.icon" [value]="item.icon" />
      <span class="action-button__label">{{ item.title | translate }}</span>
    </button>
  </div>
</ng-container>

<ng-container *ngIf="item.children && item.children.length">
  <mat-expansion-panel
    class="aca-expansion-panel"
    [expanded]="true"
    [@.disabled]="true"
  >
    <mat-expansion-panel-header expandedHeight="32px" collapsedHeight="32px" role="group" class="aca-expansion-panel-header">
      <mat-panel-title class="aca-expansion-panel-header-title">
        <div class="aca-expansion-panel-item">
          <span
            [attr.aria-label]="item.title | translate"
            [id]="item.id"
            [attr.title]="item.description | translate"
            [attr.data-automation-id]="item.id"
            class="aca-action-button aca-full-width"
          >
            <adf-icon *ngIf="item.icon" [value]="item.icon" />
            <span class="aca-action-button__label">{{ item.title | translate }}</span>
          </span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="aca-expansion-panel-body">
      <div *ngFor="let child of item.children; trackBy: trackById" class="aca-expansion-panel-item">
        <button
          acaActiveLink="aca-action-button--active"
          [action]="child"
          (actionClicked)="actionClicked.emit($event)"
          [attr.aria-label]="child.title | translate"
          [id]="child.id"
          [attr.data-automation-id]="child.id"
          [attr.title]="child.description | translate"
          mat-button
          class="aca-action-button aca-full-width"
        >
          <adf-icon *ngIf="child.icon" [value]="child.icon" />
          <span class="aca-action-button__label">{{ child.title | translate }}</span>
        </button>
      </div>
    </div>
  </mat-expansion-panel>
</ng-container>
