<div class="app-search-container">
  <mat-form-field class="app-input-form-field" appearance="outline">

    <button
      mat-icon-button
      matPrefix
      class="app-search-button"
      (click)="searchSubmit()"
      [title]="'SEARCH.BUTTON.TOOLTIP' | translate"
    >
      <mat-icon [attr.aria-label]="'SEARCH.BUTTON.ARIA-LABEL' | translate">search</mat-icon>
    </button>

    <input
      matInput
      #searchInput
      [attr.aria-label]="'SEARCH.INPUT.ARIA-LABEL' | translate"
      [type]="inputType"
      id="app-control-input"
      [formControl]="searchFieldFormControl"
      (keyup.enter)="searchSubmit()"
      [placeholder]="'SEARCH.INPUT.PLACEHOLDER' | translate"
      autocomplete="off"
    />
    <div matSuffix>
      <button mat-icon-button (click)="clear()">
        <mat-icon *ngIf="searchFieldFormControl.value.length" class="app-suffix-icon">clear</mat-icon>
      </button>
    </div>
  </mat-form-field>
</div>
