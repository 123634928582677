<div class="aca-save-search-dialog__header">
    <h2 class="aca-save-search-dialog__title">{{"APP.BROWSE.SEARCH.SAVE_SEARCH.MODAL_HEADER" | translate}}</h2>
    <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
</div>


<mat-dialog-content>
  <form [formGroup]="form" (submit)="submit()">
    <mat-form-field class="aca-save-search-dialog__form-field">
      <mat-label>{{ 'APP.BROWSE.SEARCH.SAVE_SEARCH.NAME_LABEL' | translate }}</mat-label>
      <input
        id="aca-save-search-dialog-name-input"
        [attr.aria-label]="'APP.BROWSE.SEARCH.SAVE_SEARCH.NAME_LABEL' | translate"
        matInput
        required
        [formControlName]="'name'"
        adf-auto-focus
      />

      <mat-error *ngIf="form.controls['name'].touched">
                <span *ngIf="form.controls['name'].errors?.required">
                    {{ 'APP.BROWSE.SEARCH.SAVE_SEARCH.NAME_REQUIRED_ERROR' | translate }}
                </span>
                <span *ngIf="!form.controls['name'].errors?.required && form.controls['name'].errors?.message">
                    {{ form.controls['name'].errors?.message | translate : { name: form.controls.name.value } }}
                </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="aca-save-search-dialog__form-field">
      <mat-label>{{ 'APP.BROWSE.SEARCH.SAVE_SEARCH.DESCRIPTION_LABEL' | translate }}</mat-label>
      <textarea
        id="aca-save-search-dialog-description-input"
        matInput
        [attr.aria-label]="'APP.BROWSE.SEARCH.SAVE_SEARCH.DESCRIPTION_LABEL' | translate"
        rows="4"
        [formControlName]="'description'"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-button
    id="aca-save-search-dialog-cancel-button"
    mat-dialog-close>
    {{ 'CANCEL' | titlecase | translate }}
  </button>

  <button id="aca-save-search-dialog-save-button"
          mat-flat-button
          color="primary"
          (click)="submit()"
          [disabled]="!form.valid || disableSubmitButton">
    {{ 'SAVE' | titlecase | translate}}
  </button>
</mat-dialog-actions>
