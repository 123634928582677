<aca-search-ai-input
  [searchTerm]="(inputState$ | async).searchTerm"
  [placeholder]="placeholder"
  [agentId]="agentId"
  [usedInAiResultsPage]="usedInAiResultsPage" />
<mat-divider
  [vertical]="true"
  class="aca-search-ai-input-container-divider" />
<button
  mat-icon-button
  (click)="leaveSearchInput()"
  data-automation-id="aca-search-ai-input-container-leaving-search-button"
  [title]="(isKnowledgeRetrievalPage ? 'KNOWLEDGE_RETRIEVAL.SEARCH.SEARCH_INPUT.HIDE_ANSWER' : 'KNOWLEDGE_RETRIEVAL.SEARCH.SEARCH_INPUT.HIDE_INPUT') | translate"
  class="aca-search-ai-input-container-close">
  <mat-icon>close</mat-icon>
</button>
