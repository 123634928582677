<div *ngIf="isLoading">
  <mat-progress-bar mode="indeterminate" [attr.aria-label]="'APP.INFO_DRAWER.DATA_LOADING' | translate" />
</div>
<ng-container *ngIf="!isLoading && !!displayNode">
  <adf-info-drawer [icon]="icon" [title]="node?.entry?.name || 'APP.INFO_DRAWER.TITLE'" cdkTrapFocusAutoCapture>
    <aca-toolbar [items]="actions" info-drawer-buttons />

    <adf-info-drawer-tab *ngFor="let tab of tabs" [icon]="tab.icon" [label]="tab.title">
      <adf-dynamic-tab [node]="$any(displayNode)" [id]="tab.component" [attr.data-automation-id]="tab.component" />
    </adf-info-drawer-tab>
  </adf-info-drawer>
</ng-container>
